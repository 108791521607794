import * as React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../../components/layout';
import { FaCalendar } from "react-icons/fa";
import { FaTags } from "react-icons/fa";

const BlogPost = ({ data }) => {
  return (
      <Layout pageTitle={data.mdx.frontmatter.title}>
        <header>
          <h1>{data.mdx.frontmatter.title}</h1>
          <p className="meta">
            <span><FaCalendar size={18} /> {data.mdx.frontmatter.date}</span>
          </p>
        </header>
	<MDXRenderer>
	  {data.mdx.body}
	</MDXRenderer>
      </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`

export default BlogPost;
